<footer class="footer">
    <div class="footer-copyright">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-xs-12">
            <div class="copy-right text-left">© 2024 Provided by Amaravathi Software. All rights reserved</div>
          </div>
          <div class="col-md-6 col-xs-12">
            <ul class="social-media">
              <li *ngFor="let social of socialMedia">
                <a routerLink="/home-one" [ngClass]="social.icon"></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>