import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MyService {

  constructor(private http:HttpClient) { }

  getting_data(data:any){
    this.http.post("http://amaravathimobilestores.com:9291/nodeapp/getting_data",data.value).subscribe(res=>console.log(res))
  }
}
